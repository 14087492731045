/* Añadir en tu archivo CSS global */
body {
    transition: all 0.01ms ease-in-out; /* Una transición suave */
    overflow: scroll;  /* Permite el desplazamiento */
    font-family: "Montserrat", sans-serif;
    background-color: #28282a;

  }

  p, h1, h2, h3, h4, h5, h6{
    color: white;
  }
  
  body.no-scroll {
    overflow: hidden; /* Desactiva el scroll */
    position: fixed; /* Evita que se mueva la página */
    width: 100%; /* Mantén el tamaño completo de la página */
  }
  

  /* Para navegadores basados en WebKit (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
    display: none; /* Oculta la barra de desplazamiento */
  }



/* .montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
} */