.calendar-screen{
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.rbc-calendar{
    height: 100%;
}




